.slide-enter {
  transform: translateY(-100%);
  opacity: 0;
  transition: 0.1s linear;
}
.slide-enter-active {
  transform: translateY(0%);
  opacity: 1;
}
.slide-exit {
  transform: translateY(100%);
  opacity: 1;
  transition: 0.1s linear;
}

.slide-exit-active {
  transform: translateY(100%);
  opacity: 0;
}

.gpt-transition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.gpt-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.gpt-transition-exit {
  opacity: 1;
}
.gpt-transition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

.fade-fast-enter {
  opacity: 0;
}
.fade-fast-exit {
  opacity: 1;
}
.fade-fast-enter-active {
  opacity: 1;
}
.fade-fast-exit-active {
  opacity: 0;
}
.fade-fast-enter-active,
.fade-fast-exit-active {
  transition: opacity 10ms;
}
