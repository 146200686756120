@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.App {
  /* set the background color to a light gray */
  background-color: #f5f5f5;

  /* set the height of the page to 100% of the browser window */
  width: 100vw;
  overflow-x: hidden;

  /* set the font family to the font we imported */
  font-family: "Archivo", sans-serif;

  cursor: default;
  scroll-behavior: auto;
}

#overflow-img {
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
}

.pac-container {
  z-index: 99999 !important;
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 0px 0px 20px 20px;
  box-shadow: none;
  border: 1px solid #d8d8de;
  border-top: none;
  margin-left: -1.03rem;
}

.pac-item {
  font-family: "Archivo", sans-serif !important;
}

.pac-item-query {
  font-family: "Archivo", sans-serif !important;
  font-size: 1rem;
}

.pac-icon {
  display: none;
}
